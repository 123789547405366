import { render, staticRenderFns } from "./serveDetail.vue?vue&type=template&id=66669d79&scoped=true"
import script from "./serveDetail.vue?vue&type=script&lang=js"
export * from "./serveDetail.vue?vue&type=script&lang=js"
import style0 from "./serveDetail.vue?vue&type=style&index=0&id=66669d79&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66669d79",
  null
  
)

export default component.exports